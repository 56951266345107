import React from "react";

import { Icon, IconType } from "components/_icons/Icon";
import { Pill, PillSize } from "components/_pills/Pill";
import { Text } from "components/Text";

import { FeedbackCategory } from "ts/enums/feedbackCategory";
import { Color } from "ts/enums/color";

type FeedbackTypePillProps = {
  isSelected: boolean;
  isDisabled: boolean;
  isSuccess: boolean;
  handleClick: (type: FeedbackCategory) => void;
  type: FeedbackCategory;
};

export const FeedbackTypePill = ({
  isSelected,
  isDisabled,
  isSuccess,
  type,
  handleClick,
}: FeedbackTypePillProps) => (
  <Pill
    size={PillSize.sm}
    onClick={() => (!isDisabled ? handleClick(type) : undefined)}
    inverted={isSelected}
    applyGridMargin
    disabled={isDisabled}
    style={{ pointerEvents: isSuccess ? "none" : "auto" }}
  >
    <>
      <Text resource={`feedback.pill.${type}`} />
      {isSelected && (
        <Icon
          type={IconType.checkCircle}
          size={12}
          style={{ marginLeft: 6 }}
          color={isDisabled ? Color.indigo10 : isSelected ? Color.white : Color.indigo50}
        />
      )}
    </>
  </Pill>
);
