import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ImportModalState {
  importModalOpen: boolean;
  importModalFileId?: number;
  importModalAnalysisId?: number;
}

const initialState: ImportModalState = {
  importModalOpen: false,
  importModalFileId: undefined,
  importModalAnalysisId: undefined,
};

const importModalSlice = createSlice({
  name: "importModal",
  initialState,
  reducers: {
    setImportModalOpen: (state, action: PayloadAction<boolean>) => {
      state.importModalOpen = action.payload;
    },
    setImportModalFileId: (state, action: PayloadAction<number>) => {
      state.importModalFileId = action.payload;
      state.importModalAnalysisId = undefined;
    },
    setImportModalAnalysisId: (state, action: PayloadAction<number>) => {
      state.importModalAnalysisId = action.payload;
      state.importModalFileId = undefined;
    },
  },
});

export const { setImportModalOpen, setImportModalFileId, setImportModalAnalysisId } =
  importModalSlice.actions;

export default importModalSlice.reducer;
