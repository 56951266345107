import {
  AnalysisStatus,
  CategorizationType,
  ExcludedCommentWordCount,
} from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { DEFAULT_PRECISION_SELECTION } from "assets/constants/precisionSelection";
import { EXCLUDED_COMMENT_SUGGESTION_LIMIT } from "assets/constants/excludedCommentSuggestionLimit";
import { fetchPreFilteringWordSuggestions, fetchUsedModels } from "./thunks";

import { getModelSelectionDropdownData } from "components/ModelSelection/helpers";

import { Model, ModelSelectionDropdownData } from "ts/models";
import { AnalysisDetails, PreFilteringSuggestedWords } from "ts/analysis";
import { Feature } from "ts/enums/feature";

import { findPrecisionNumber } from "utils/precision";
import { getUniqueEnvs } from "utils/getUniqueEnvs";
import { isFeatureActive } from "utils/isFeatureActive";

export type FilteredCommentType = {
  comment: string;
  selectAllEnum?: ExcludedCommentWordCount;
  selectAllExclusionCount?: number;
  isUserInput?: boolean;
};

type RemoveCommentActionPayload = {
  commentDetails: FilteredCommentType;
  index: number;
};

type InitializeSettingsPayload = {
  isModelOutdated: boolean;
  details: AnalysisDetails;
};
type SettingsState = {
  selectedModel: Model | null;
  selectedCustomModel: Model | null;
  isModelOutdated: boolean;
  selectedModelGraphId: number | null;
  analysisDetails: AnalysisDetails | null;
  selectedPrecision: number;
  selectedColumns: string[];
  selectedCommenterColumn: string | null;
  selectedVariableMapping: number | null;
  isMultilingualEnabled: boolean;
  showOverwriteModal: boolean;
  showEnvDropdown: boolean;
  selectedEnv: string;
  envDropdownOptions: string[];
  isInitialLoadingFinished: boolean;
  modelOptionsDropdownData: ModelSelectionDropdownData;
  commentCountDropdownOpen: boolean;
  commentExclusionCount: number;
  inputActive: boolean;
  excludedCommentList: FilteredCommentType[];
  userInput: string;
  preFilteringWordSuggestions: PreFilteringSuggestedWords;
  isPrefilteringSuggestionsLoading: boolean;
  excludedCommentLimit: number;
  loadMoreButtonClicked: boolean;
  suggestedColumnsBannerIsOpen: boolean;
};

const initialState: SettingsState = {
  selectedModel: null,
  selectedCustomModel: null,
  isModelOutdated: false,
  selectedModelGraphId: null,
  analysisDetails: null,
  selectedPrecision: DEFAULT_PRECISION_SELECTION.value,
  selectedColumns: [],
  envDropdownOptions: [],
  selectedEnv: null,
  isMultilingualEnabled: true,
  selectedCommenterColumn: null,
  selectedVariableMapping: null,
  showOverwriteModal: false,
  showEnvDropdown: false,
  isInitialLoadingFinished: false,
  modelOptionsDropdownData: {
    categorizedModelList: [],
    options: {
      models: {
        [CategorizationType.EEC]: [],
        [CategorizationType.ELC]: [],
        [CategorizationType.SCE]: [],
      },
      customModels: {
        [CategorizationType.EEC]: [],
        [CategorizationType.ELC]: [],
        [CategorizationType.SCE]: [],
      },
    },
  },
  commentCountDropdownOpen: false,
  commentExclusionCount: 5,
  inputActive: false,
  excludedCommentList: [],
  userInput: "",
  preFilteringWordSuggestions: null,
  isPrefilteringSuggestionsLoading: false,
  excludedCommentLimit: EXCLUDED_COMMENT_SUGGESTION_LIMIT,
  loadMoreButtonClicked: false,
  suggestedColumnsBannerIsOpen: true,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSelectedModel: (state, action: PayloadAction<Model | null>) => {
      if (state.selectedModel?.graphId === action.payload?.graphId) return;
      state.selectedModel = action.payload;
      state.selectedCustomModel = null;
    },
    setSelectedCustomModel: (state, action: PayloadAction<Model | null>) => {
      state.selectedCustomModel = action.payload;
    },
    setIsModelOutdated: (state, action: PayloadAction<boolean>) => {
      state.isModelOutdated = action.payload;
    },
    setSelectedPrecision: (state, action: PayloadAction<number>) => {
      state.selectedPrecision = action.payload;
    },
    setIsMultilingualEnabled: (state, action: PayloadAction<boolean>) => {
      state.isMultilingualEnabled = action.payload;
    },
    setSelectedColumns: (state, action: PayloadAction<string[]>) => {
      state.excludedCommentLimit = EXCLUDED_COMMENT_SUGGESTION_LIMIT;
      if (action.payload.some((sc) => sc === state.selectedCommenterColumn)) {
        state.selectedCommenterColumn = null;
      }
      state.selectedColumns = action.payload;
    },
    setSelectedEnv: (state, action: PayloadAction<string>) => {
      if (state.selectedEnv === action.payload) return;
      state.selectedEnv = action.payload;
      state.selectedCustomModel = null;
      state.selectedModel = state.modelOptionsDropdownData.categorizedModelList.filter(
        (mo) => mo.env === action.payload
      )[0];
    },
    setAnalysisDetails: (state, action: PayloadAction<AnalysisDetails>) => {
      state.analysisDetails = action.payload;
    },
    setSelectedCommenterColumn: (state, action: PayloadAction<string | null>) => {
      state.selectedCommenterColumn = action.payload;
    },
    setSelectedVariableMapping(state, action: PayloadAction<number | null>) {
      state.selectedVariableMapping = action.payload;
    },
    setShowOverwriteModal: (state, action: PayloadAction<boolean>) => {
      state.showOverwriteModal = action.payload;
    },
    setSelectedModelGraphId: (state, action: PayloadAction<number | null>) => {
      state.selectedModelGraphId = action.payload;
    },
    setShowEnvDropdown: (state, action: PayloadAction<boolean>) => {
      state.showEnvDropdown = action.payload;
    },
    setIsInitialLoadingFinished: (state, action: PayloadAction<boolean>) => {
      state.isInitialLoadingFinished = action.payload;
    },
    setModelOptionsDropdownData: (state, action: PayloadAction<ModelSelectionDropdownData>) => {
      state.modelOptionsDropdownData = action.payload;
    },
    setCommentCountDropdownOpen: (state, action: PayloadAction<boolean>) => {
      state.commentCountDropdownOpen = action.payload;
    },
    setCommentExclusionCount: (state, action: PayloadAction<number>) => {
      state.excludedCommentLimit = EXCLUDED_COMMENT_SUGGESTION_LIMIT;
      state.commentExclusionCount = action.payload;
    },
    setInputActive: (state, action: PayloadAction<boolean>) => {
      state.inputActive = action.payload;
    },
    setExcludedCommentList: (state, action: PayloadAction<FilteredCommentType>) => {
      if (action.payload.selectAllExclusionCount) {
        state.excludedCommentList = state.excludedCommentList.filter(
          (comment) =>
            comment.isUserInput ||
            (comment.comment.split(" ").length > state.commentExclusionCount &&
              !comment.selectAllEnum)
        );
        state.excludedCommentList.unshift(action.payload);
      } else {
        state.excludedCommentList.push(action.payload);
      }
    },
    setUserInput: (state, action: PayloadAction<string>) => {
      state.userInput = action.payload;
    },
    setLoadMoreButtonClicked: (state, action: PayloadAction<boolean>) => {
      state.loadMoreButtonClicked = action.payload;
    },
    closeSuggestedColumnsBanner: (state) => {
      state.suggestedColumnsBannerIsOpen = false;
    },
    incrementExcludedCommentLimit: (state) => {
      state.excludedCommentLimit += EXCLUDED_COMMENT_SUGGESTION_LIMIT;
    },
    removeComment: (state, action: PayloadAction<RemoveCommentActionPayload>) => {
      if (action.payload.commentDetails.selectAllExclusionCount) {
        state.excludedCommentList = state.excludedCommentList.filter(
          (filteredComment) =>
            filteredComment.selectAllExclusionCount !==
            action.payload.commentDetails.selectAllExclusionCount
        );
      } else {
        state.excludedCommentList.splice(action.payload.index, 1);
      }
    },
    clearAllComments: (state) => {
      state.excludedCommentList = [];
    },
    initializeSettings: (state, action: PayloadAction<InitializeSettingsPayload>) => {
      const { details, isModelOutdated } = action.payload;
      state.selectedModelGraphId = details.graphId;
      state.isModelOutdated = isModelOutdated;
      state.selectedColumns = details.selectedColumns ?? details.suggestedColumns ?? [];
      if (details.precision) state.selectedPrecision = findPrecisionNumber(details.precision);
      state.selectedCommenterColumn = details.selectedCommentersColumn;
      state.selectedVariableMapping = details.selectedVariableMappingId;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsedModels.fulfilled, (state, action) => {
      state.modelOptionsDropdownData = getModelSelectionDropdownData(action.payload, true);

      // Set available environments
      state.envDropdownOptions = getUniqueEnvs(state.modelOptionsDropdownData.options);
      state.isMultilingualEnabled = isFeatureActive(Feature.multilingualAnalysis)
        ? state.analysisDetails.status === AnalysisStatus.NotAnalyzed ||
          !!state.analysisDetails?.translationLanguages
        : true;
      const modelEnv = state.selectedModel?.env || state.analysisDetails.env;
      const defaultEnv = state.envDropdownOptions[0] || state.analysisDetails.env;
      const selectedEnv =
        modelEnv && state.envDropdownOptions.includes(modelEnv) ? modelEnv : defaultEnv;
      state.selectedEnv = selectedEnv;

      // Set selected Model
      //CASE OUTDATED MODEL
      if (state.isModelOutdated) {
        const outdatedModel = {
          graphId: state.analysisDetails.selectedModel.graphId,
          env: state.analysisDetails.selectedModel.env,
          categorizationType: state.analysisDetails.selectedModel.family,
          languages: state.analysisDetails.selectedModel.languages,
          isPowerset: state.analysisDetails.selectedModel.isPowerset,
          isOutdated: true,
        } as Model;

        state.selectedModel = outdatedModel;
        // CASE OUTDATED CUSTOM MODEL
        if (
          state.analysisDetails?.selectedModel?.customModelId &&
          state.modelOptionsDropdownData.options.customModels
        ) {
          state.selectedCustomModel = state.modelOptionsDropdownData.options.customModels[
            state.analysisDetails.selectedModel.family
          ].find((mo) => mo.customModelId === state.analysisDetails.selectedModel.customModelId);
        }
        // CASE CUSTOM MODEL
      } else if (
        state.analysisDetails?.selectedModel?.customModelId &&
        state.modelOptionsDropdownData.options.customModels
      ) {
        state.selectedModel = state.modelOptionsDropdownData.options.models[
          state.analysisDetails.selectedModel.family
        ].find((mo) => mo.graphId === state.analysisDetails.graphId);

        state.selectedCustomModel = state.modelOptionsDropdownData.options.customModels[
          state.analysisDetails.selectedModel.family
        ].find((mo) => mo.customModelId === state.analysisDetails.selectedModel.customModelId);
        // CASE MODEL
      } else if (state.analysisDetails.graphId) {
        state.selectedModel = state.modelOptionsDropdownData.categorizedModelList.find(
          (mo) => mo.graphId === state.analysisDetails.graphId
        );
      }
      // CASE BASE MODEL
      else if (
        state.analysisDetails.status === AnalysisStatus.Completed &&
        !state.analysisDetails.graphId
      ) {
        state.selectedModel = null;
      }
      // CASE NOT YET ANALYZED
      else {
        state.selectedModel = state.modelOptionsDropdownData.categorizedModelList.filter(
          (mo) => mo.env === selectedEnv
        )[0];
      }
    });

    builder.addCase(fetchPreFilteringWordSuggestions.pending, (state) => {
      if (state.selectedColumns.length > 0) {
        state.isPrefilteringSuggestionsLoading = true;
      }
    }),
      builder.addCase(fetchPreFilteringWordSuggestions.fulfilled, (state, action) => {
        state.isPrefilteringSuggestionsLoading = false;
        state.loadMoreButtonClicked = false;
        state.preFilteringWordSuggestions = action.payload;
      });
  },
});

export const {
  setSelectedModel,
  setSelectedCustomModel,
  setSelectedPrecision,
  setSelectedColumns,
  setSelectedCommenterColumn,
  setSelectedVariableMapping,
  setShowOverwriteModal,
  setIsInitialLoadingFinished,
  setModelOptionsDropdownData,
  setIsModelOutdated,
  setShowEnvDropdown,
  setSelectedEnv,
  initializeSettings,
  setIsMultilingualEnabled,
  incrementExcludedCommentLimit,
  setSelectedModelGraphId,
  setAnalysisDetails,
  setCommentCountDropdownOpen,
  setCommentExclusionCount,
  setInputActive,
  setExcludedCommentList,
  setUserInput,
  setLoadMoreButtonClicked,
  closeSuggestedColumnsBanner,
  removeComment,
  clearAllComments,
  clearState,
} = settingsSlice.actions;

export default settingsSlice.reducer;
