import React from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "store";

import { Text } from "components/Text";
import { Select } from "components/_inputs/Select";

import { Color } from "ts/enums/color";
import { DataPreviewTable } from "./DataPreviewTable";
import { ImportMode } from "@explorance/mly-types";
import {
  setSelectedImportType,
  setSelectedUniqueColumn,
} from "store/analysisSettings/dataSourceSlice";
import { DataSourceModalType } from "ts/enums/dataSourceModalType";

type Props = {
  type: DataSourceModalType;
};

export const ImportSettingsStep = ({ type }: Props) => {
  const state = useAppSelector((state) => state.dataSource);
  const dispatch = useAppDispatch();

  const importTypeDropdownOptions = [
    {
      label: <Text resource="dataSource.dropdown.importType.[AppendAndUpdate]" />,
      value: ImportMode.AppendAndUpdate,
    },
    {
      label: <Text resource="dataSource.dropdown.importType.[ImportAlways]" />,
      value: ImportMode.ImportAlways,
    },
  ];

  const uniqueColumnDropdownOptions = state.suggestedUniqueColumns?.map((option) => ({
    label: option,
    value: option,
  }));

  const getImportTypeDropdownOption = (value: ImportMode) => {
    if (!value) return importTypeDropdownOptions[0];
    return importTypeDropdownOptions.find((option) => option.value === value);
  };

  const getUniqueColumnDropdownOption = (value: string) => {
    return uniqueColumnDropdownOptions.find((option) => option.value === value);
  };

  const renderHeaderSettings = () => {
    if (type === DataSourceModalType.ImportFile) {
      return (
        <Select
          options={importTypeDropdownOptions}
          selectedOption={getImportTypeDropdownOption(state.selectedImportType)}
          buttonStyle={{ width: "175px" }}
          dropdownWidth="175px"
          selectLabelKey="modal.import.stepTwo.type"
          dropdownPosition={{ top: 35, right: 0 }}
          handleChange={(option) => dispatch(setSelectedImportType(option))}
        />
      );
    }

    if (uniqueColumnDropdownOptions?.length > 0) {
      return (
        <Select
          options={uniqueColumnDropdownOptions}
          selectedOption={getUniqueColumnDropdownOption(state.selectedUniqueColumn)}
          buttonStyle={{ width: "155px" }}
          dropdownWidth="155px"
          dropdownPosition={{ top: 35, right: 0 }}
          selectLabelKey="modal.import.stepTwo.uniqueColumn"
          handleChange={(option) => dispatch(setSelectedUniqueColumn(option))}
        />
      );
    }
  };

  const headerTitleResourceKey = {
    [DataSourceModalType.ImportFile]: "modal.import.stepTwo.title",
    [DataSourceModalType.ViewData]: "modal.import.editDataTypes.title",
  }[type];

  return (
    <>
      <StyledHeader>
        <StyledHeaderTextSection>
          <b>
            <Text resource={headerTitleResourceKey} />
          </b>
          <StyledHeaderDescription>
            <Text resource="modal.import.stepTwo.description" />
            <b>
              <Text
                resource={{
                  key: "dataSource.totalCount",
                  args: [`${state.analysisFileSummary?.rowCount}`],
                }}
              />
            </b>
          </StyledHeaderDescription>
        </StyledHeaderTextSection>
        <StyledHeaderSettings>{renderHeaderSettings()}</StyledHeaderSettings>
      </StyledHeader>
      <StyledProgressBar>
        <StyledCurrentProgress />
      </StyledProgressBar>
      <StyledDataPreviewTableContainer>
        <DataPreviewTable />
      </StyledDataPreviewTableContainer>
    </>
  );
};

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
`;

const StyledHeaderTextSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  padding-right: 20px;
`;

const StyledHeaderSettings = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledProgressBar = styled.div`
  width: calc(100% + 48px);
  height: 5px;
  margin-left: -24px;
  background-color: ${Color.blue20};
  margin-bottom: 12px;
`;

const StyledCurrentProgress = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${Color.green50};
`;

const StyledDataPreviewTableContainer = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
`;

const StyledHeaderDescription = styled.div`
  margin-top: 5px;
  font-size: 14px;
  display: flex;
  b {
    padding-left: 4px;
  }

  @media (max-width: 1366px) {
    display: block;
    b {
      padding-left: 0;
    }
  }
`;
