import React, { useEffect } from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "store";
import { setImportModalOpen } from "store/importProcess/importModalSlice";

import { Modal } from "components/_modals/Modal";
import { ImportSettingsStep } from "./ImportSettingsStep";
import { UploadFileStep } from "./UploadFileStep";
import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { FileUploadStatus } from "@explorance/mly-types";
import { Color } from "ts/enums/color";
import { fetchAllAnalysisFilesSummary, fetchFileSummary } from "store/analysisSettings/thunks";
import { resetDataSource } from "store/analysisSettings/dataSourceSlice";
import { DataSourceModalType } from "ts/enums/dataSourceModalType";
import { saveFileSettings, updateAnalysisFileSettings } from "services/files";

const modalStyles = {
  content: {
    margin: "auto",
    boxShadow: "0px 4px 16px #B6BACB29",
    width: "fit-content",
    height: "fit-content",
    border: `1px solid ${Color.neutral30}`,
    borderRadius: "5px",
    backgroundColor: Color.white,
    transition: "height .5s ease-in-out",
    overflow: "hidden",
  },
};

type Props = {
  fileUploadStatus: FileUploadStatus;
  type: DataSourceModalType;
};

export const DataSourceModal = ({ fileUploadStatus, type }: Props) => {
  const state = useAppSelector((state) => state.importProcess);

  const dataSourceState = useAppSelector((state) => state.dataSource);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!state.importModalOpen) return;
    if (type === DataSourceModalType.ImportFile && state.importModalFileId) {
      dispatch(fetchFileSummary(state.importModalFileId));
    }
    if (type === DataSourceModalType.ViewData && state.importModalAnalysisId) {
      dispatch(fetchAllAnalysisFilesSummary(state.importModalAnalysisId));
    }
  }, [dispatch, type, state.importModalAnalysisId, state.importModalFileId, state.importModalOpen]);

  const handleCloseModal = () => {
    dispatch(setImportModalOpen(false));
    dispatch(resetDataSource());
  };

  const step = [FileUploadStatus.Uploading].includes(fileUploadStatus) ? 1 : 2;

  const handleSave = async () => {
    switch (step) {
      case 1:
        dispatch(setImportModalOpen(true));
        break;
      case 2:
        switch (type) {
          case DataSourceModalType.ImportFile:
            await saveFileSettings(state.importModalFileId, {
              demographics: dataSourceState.updatedDemographicData,
              selectedUniqueColumn: dataSourceState.selectedUniqueColumn || null,
              importMode: dataSourceState.selectedImportType,
            });
            // push(routes.settingsPage(state.importModalAnalysisId)); @Todo: implement this by awaiting the creation of the analysis
            break;
          case DataSourceModalType.ViewData:
            await updateAnalysisFileSettings(
              state.importModalAnalysisId,
              dataSourceState.updatedDemographicData
            );
            break;
          default:
            break;
        }
        dispatch(setImportModalOpen(false));
        break;
      default:
        break;
    }
  };

  const renderMainContent = () => {
    if (fileUploadStatus === FileUploadStatus.Failed) {
      return <div>Upload failed (Todo)</div>;
    }
    switch (step) {
      case 1:
        return <UploadFileStep />;
      case 2:
        return <ImportSettingsStep type={type} />;
      default:
        return null;
    }
  };

  const isSaveCTADisabled =
    (type === DataSourceModalType.ImportFile &&
      ![FileUploadStatus.Completed, FileUploadStatus.WaitingForInput].includes(fileUploadStatus)) ||
    (type === DataSourceModalType.ViewData && fileUploadStatus !== FileUploadStatus.Completed);

  const saveCTAResourceKey = {
    [DataSourceModalType.ImportFile]: "button.next",
    [DataSourceModalType.ViewData]: "button.save",
  }[type];

  return (
    <Modal isOpen={state.importModalOpen} styles={modalStyles}>
      <StyledImportProgressModalContent>
        {renderMainContent()}
        <StyledActionsSection>
          <Button variant={ButtonVariant.outline} size={ButtonSize.md} onClick={handleCloseModal}>
            <Text resource="button.close" />
          </Button>
          {step === 2 && (
            <Button
              variant={ButtonVariant.primary}
              size={ButtonSize.md}
              disabled={isSaveCTADisabled}
              onClick={handleSave}
            >
              <Text resource={saveCTAResourceKey} />
            </Button>
          )}
        </StyledActionsSection>
      </StyledImportProgressModalContent>
    </Modal>
  );
};

const StyledImportProgressModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170px;
  height: 680px;
  @media (max-width: 1366px) {
    width: 950px;
    height: 680px;
  }
`;

const StyledActionsSection = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  justify-content: flex-end;
  margin-top: auto;
  padding-top: 24px;
`;
