import React, { useContext } from "react";
import styled from "styled-components";

import { formatSearchTerms } from "utils/comments";
import { Color } from "ts/enums/color";
import { Comment as CommentType } from "ts/comments";
import { AnalysisContext } from "context/AnalysisContext";

type Props = {
  comment: CommentType;
  searchField: string;
};

export const CommentText = ({ comment, searchField }: Props) => {
  const [state] = useContext(AnalysisContext);

  const searchRegExp = () => {
    if (searchField && searchField.length > 0) {
      const formattedSearch = formatSearchTerms(searchField);
      return new RegExp(`(${formattedSearch})`, "gi");
    }
  };

  const renderCommentParts = () => {
    const parts =
      state.selectedTranslationLanguage &&
      Object.keys(comment.translations).includes(state.selectedTranslationLanguage)
        ? comment.translations[state.selectedTranslationLanguage].split(searchRegExp())
        : comment.value.split(searchRegExp());
    return parts.map((part, i) => (
      <span key={i} className={searchRegExp().test(part) ? "highlight" : undefined}>
        {part}
      </span>
    ));
  };

  if (!comment.value) return null;

  return (
    <StyledCommentText>
      {searchField
        ? renderCommentParts()
        : state.selectedTranslationLanguage &&
          comment.translations[state.selectedTranslationLanguage]
        ? comment.translations[state.selectedTranslationLanguage]
        : comment.value}
    </StyledCommentText>
  );
};

const StyledCommentText = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${Color.gray50};
  margin: 0;
  white-space: break-spaces;

  .highlight {
    background: ${Color.blue40};
    color: ${Color.white};
    // keep this value here to prevent highlight of extra characters in Chrome and Edge (to see, comment out this value and search for "of")
    font-weight: 500;
  }
`;
