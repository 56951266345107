import React from "react";
import styled from "styled-components";

import { AnalysisResultsSection } from "./AnalysisResultsSection";
import { CommentFeedbackForm } from "../FeedbackForm/CommentFeedbackForm";
import { DemographicsSection } from "./DemographicsSection";

import { Text } from "components/Text";
import { Popup } from "components/Popup";

import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { Comment } from "ts/comments";
import { PopupPosition } from "ts/enums/popupPosition";

type Props = {
  comment: Comment;
  isOpen: boolean;
  anchorEl: null | HTMLElement;
};

export const CommentDetailsPopup = ({ comment, isOpen, anchorEl }: Props) => (
  <StyledCommentDetailsContainer>
    <Popup
      isOpen={isOpen}
      popupStyles={{ width: 455, maxHeight: 475, padding: 10 }}
      placement={PopupPosition.right}
      anchorEl={anchorEl}
      fixedPopup
    >
      <StyledPopupHeader>
        <StyledCommentDetailsTitle>
          <Text resource="comments.detailsPopup.title" />
        </StyledCommentDetailsTitle>
        <StyledCommentDetailsColumn>{comment.selectedColumn}</StyledCommentDetailsColumn>
        <StyledCommentDetailsBody>{comment.value}</StyledCommentDetailsBody>
      </StyledPopupHeader>
      <StyledPopupBody>
        <AnalysisResultsSection
          results={{
            alertScore: comment.alertScore,
            hasAlert: comment.hasAlert,
            commentPolarity: comment.commentPolarity,
            categorizedTopics: comment.categorizedTopics,
            recommendations: comment.recommendations,
            categorizedRecommendations: comment.categorizedRecommendations,
          }}
        />
        <DemographicsSection sectionDetails={comment.demographics || {}} />
        <CommentFeedbackForm comment={comment} />
      </StyledPopupBody>
    </Popup>
  </StyledCommentDetailsContainer>
);

const StyledCommentDetailsContainer = styled.div`
  position: relative;
`;

const StyledPopupHeader = styled.div`
  background-color: ${Color.sky15};
  padding: 12px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
`;

const StyledCommentDetailsBody = styled.div`
  font-size: 14px;
  color: ${Color.gray50};
  max-height: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCommentDetailsColumn = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${Color.gray50};
`;

const StyledCommentDetailsTitle = styled.div`
  font-size: 0.75em;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${Color.gray30};
`;

const StyledPopupBody = styled.div`
  margin: 8px 0 0px 2px;
  overflow-y: auto;
  z-index: ${ZIndexStackingContext.high + 1};

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border: none;
    background: ${Color.sky50} 0% 0% no-repeat padding-box;
  }

  ::-webkit-scrollbar-track {
    background: ${Color.sky20} 0% 0% no-repeat padding-box;
    border-radius: 6px;
  }
`;
