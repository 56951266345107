import React from "react";
import styled from "styled-components";

import { UserInputSection } from "./InputSection";
import { RecommendedComments } from "./RecommendedComments";
import { CollapsibleSection } from "components/CollapsibleSection";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";

export const PreFiltering = () => (
  <StyledParentContainer>
    <CollapsibleSection
      titleLeftResource="analysisSettings.prefiltering.header"
      hideHeaderBorder
      hideBoxShadow
    >
      <StyledDescriptionHeader>
        <Text resource="analysisSettings.prefiltering.description" />
      </StyledDescriptionHeader>
      <StyledInputsContainer>
        <RecommendedComments />
        <UserInputSection />
      </StyledInputsContainer>
    </CollapsibleSection>
  </StyledParentContainer>
);

const StyledParentContainer = styled.div`
  margin: 20px 0;
  font-weight: bold;
`;

const StyledDescriptionHeader = styled.div`
  font-size: 14px;
  color: ${Color.gray40};
  font-weight: normal;
`;

const StyledInputsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  font-weight: normal;
`;
