import React, { ReactNode } from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "store";

import { DemographicTypes, FileUploadStatus } from "@explorance/mly-types";
import { Color } from "ts/enums/color";

import { Icon, IconType } from "components/_icons/Icon";
import { Select } from "components/_inputs/Select";
import { LoadingDots } from "components/LoadingDots";
import { Text } from "components/Text";

import { voidFunc } from "utils/voidFunc";
import { updateDemographicField } from "store/analysisSettings/dataSourceSlice";

export const DataPreviewTable = () => {
  const { analysisFileSummary, uploadStatus, updatedDemographicData } = useAppSelector(
    (state) => state.dataSource
  );
  const dispatch = useAppDispatch();

  if (!analysisFileSummary || uploadStatus === FileUploadStatus.Uploading) {
    return (
      <StyledLoadingDotsContainer>
        <LoadingDots />
      </StyledLoadingDotsContainer>
    );
  }

  const isColumnSelected = voidFunc as any; // @Todo: implement this

  const getDatatypeDropdownOptions = (suggestedTypes: DemographicTypes[]) =>
    suggestedTypes.map((st) => ({
      label: <Text resource={`dataSource.dropdown.suggestedType.[${st}]`} />,
      value: st,
    }));

  return (
    <StyledDataPreviewTable>
      <thead>
        <tr>
          {analysisFileSummary.demographics?.map((d) => {
            const datatypeDropdownOptions = getDatatypeDropdownOptions(d.suggestedTypes);
            return (
              <StyledTableHeaderContainer key={d.id} isSelected={isColumnSelected(d)}>
                <StyledTableHeader>
                  <StyledHeaderTitle>{d.name}</StyledHeaderTitle>
                  <StyledHeaderIconContainer>
                    {isColumnSelected(d) && (
                      <Icon type={IconType.checkCircle} color={Color.white} size={16} />
                    )}
                  </StyledHeaderIconContainer>
                </StyledTableHeader>
                {updatedDemographicData?.length > 0 && (
                  <StyledDataTypeSelection>
                    <Select
                      buttonStyle={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "2px",
                        fontWeight: "normal",
                      }}
                      selectStyle={{ width: "min(100%, 144px)" }}
                      dropdownWidth="clamp(112px, 100%, 144px)"
                      options={datatypeDropdownOptions}
                      selectedOption={datatypeDropdownOptions.find(
                        (option) =>
                          option.value ===
                          updatedDemographicData.find((data) => data.id === d.id)?.selectedType
                      )}
                      handleChange={(type: DemographicTypes) =>
                        dispatch(
                          updateDemographicField({ demographicId: d.id, selectedType: type })
                        )
                      }
                      dropdownPosition={{ top: 35 }}
                    />
                  </StyledDataTypeSelection>
                )}
              </StyledTableHeaderContainer>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {analysisFileSummary.comments?.map((comment, commentIndex) => (
          <tr key={commentIndex}>
            {analysisFileSummary.demographics?.map((d, demographicIndex) => (
              <td key={`${commentIndex}-${demographicIndex}`}>
                <StyledTableContentContainer>
                  {comment[d.name] as ReactNode}
                </StyledTableContentContainer>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledDataPreviewTable>
  );
};

const StyledDataPreviewTable = styled.table`
  text-align: left;
  width: auto;
  border-spacing: 0;
  cursor: default;
  border-collapse: separate;
  table-layout: fixed;
  font-size: 0.875em;
  overflow: scroll;

  td {
    border-bottom: 1px solid ${Color.blue20};
    margin: 0px;
    padding: 16px;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 1365px) {
    th,
    td {
      max-width: 480px;
    }
  }

  @media (min-width: 1366px) {
    th,
    td {
      max-width: 600px;
    }
  }
`;

const StyledTableHeaderContainer = styled.th<{ isSelected: boolean }>`
  box-sizing: content-box;
  font-weight: bold;
  text-align: left;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  border-top: 1px solid ${Color.blue20};
  border-bottom: 1px solid ${Color.blue20};
  min-width: 95px;
  max-width: 450px;
  vertical-align: top;
  background-color: ${(props) => (props.isSelected ? Color.gray40 : Color.sky20)};
  color: ${(props) => (props.isSelected ? Color.white : Color.gray50)};
`;

const StyledTableHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledHeaderTitle = styled.div`
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 18px;
`;

const StyledHeaderIconContainer = styled.div`
  float: right;
`;

const StyledTableContentContainer = styled.div`
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  color: ${Color.gray50};
`;

const StyledDataTypeSelection = styled.div`
  border-top: 1px solid ${Color.blue20};
  padding: 10px 16px;
`;

const StyledLoadingDotsContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
