import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type DiscussionThreadState = {
  userInput: string;
  showCharacterCount: boolean;
  inputHeight: number;
};

const INITIAL_INPUT_HEIGHT = 34;
const EXPANDED_INPUT_HEIGHT = 120;

const initialState: DiscussionThreadState = {
  userInput: "",
  showCharacterCount: false,
  inputHeight: INITIAL_INPUT_HEIGHT,
};

const discussionThreadSlice = createSlice({
  name: "discussionThreadSlice",
  initialState,
  reducers: {
    setUserInput: (state, action: PayloadAction<string>) => {
      state.userInput = action.payload;
    },
    setShowCharacterCount: (state, action: PayloadAction<boolean>) => {
      state.showCharacterCount = action.payload;
    },
    setInitialInputHeight: (state) => {
      state.inputHeight = INITIAL_INPUT_HEIGHT;
    },
    setExpandedInputHeight: (state) => {
      state.inputHeight = EXPANDED_INPUT_HEIGHT;
    },
    resetInput: (state) => {
      state.inputHeight = INITIAL_INPUT_HEIGHT;
      state.showCharacterCount = false;
      state.userInput = "";
    },
  },
});

export const {
  setUserInput,
  setShowCharacterCount,
  setInitialInputHeight,
  setExpandedInputHeight,
  resetInput,
} = discussionThreadSlice.actions;

export default discussionThreadSlice.reducer;
