import React, { ComponentType, useState, useEffect, ReactNode } from "react";
import styled, { css } from "styled-components";

import { createPortal } from "react-dom";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

export enum SideDrawerPosition {
  left = "left",
  right = "right",
}

export enum SideDrawerType {
  fixed = "fixed",
  wrapped = "wrapped",
  card = "card",
}

let timeoutId: ReturnType<typeof setTimeout>;

const cardTypeDrawerOffsetLength = 15;

type Props = {
  render: ComponentType<any>;
  isOpen: boolean;
  closeHandler: () => any;
  renderComponentProps?: Record<string, unknown>;
  type?: SideDrawerType;
  position?: SideDrawerPosition;
  width?: string;
  backdropColor?: string;
  transition?: string;
  children?: ReactNode;
};

export const SideDrawer = (props: Props) => {
  const {
    children,
    render: Render,
    isOpen,
    closeHandler,
    renderComponentProps,
    type = SideDrawerType.fixed,
    position = SideDrawerPosition.right,
    width = "550px",
    backdropColor = "rgba(0,0,0,0.07)",
    transition = "200ms ease-out",
  } = props;

  // useState
  const [isActive, setIsActive] = useState<boolean>(isOpen);
  const [drawerCSSLeft, setDrawerCSSLeft] = useState<string>(
    isOpen && type === SideDrawerType.card
      ? cardTypeDrawerOffsetLength + "px"
      : isOpen
      ? "0"
      : "-" + width
  );
  const [drawerCSSRight, setDrawerCSSRight] = useState<string>(
    isOpen && type === SideDrawerType.card
      ? cardTypeDrawerOffsetLength + "px"
      : isOpen
      ? "0"
      : "-" + width
  );
  const [backdropOpacity, setBackdropOpacity] = useState<number>(isOpen ? 1 : 0);

  // useEffect
  useEffect(() => {
    if (isOpen) {
      clearTimeout(timeoutId);
      setIsActive(true);

      timeoutId = setTimeout(() => {
        if (type === SideDrawerType.card) {
          setDrawerCSSLeft(cardTypeDrawerOffsetLength + "px");
          setDrawerCSSRight(cardTypeDrawerOffsetLength + "px");
        } else {
          setDrawerCSSLeft("0");
          setDrawerCSSRight("0");
        }
        setBackdropOpacity(1);
      }, 20);
    } else {
      clearTimeout(timeoutId);
      setDrawerCSSLeft("-" + width);
      setDrawerCSSRight("-" + width);
      setBackdropOpacity(0);

      const transitionDuration = transition.split(" ")[0];
      const transitionDurationInt = transitionDuration.endsWith("ms")
        ? parseInt(transitionDuration.slice(0, -2))
        : parseInt(transitionDuration.slice(0, -1));
      timeoutId = setTimeout(() => setIsActive(false), transitionDurationInt);
    }
  }, [isOpen, transition, width, type]);

  const renderedJsx = (
    <StyledDrawerFrame
      type={type}
      isActive={isActive}
      className={type === SideDrawerType.card ? "main-content override" : ""}
    >
      <StyledDrawerScrim
        type={type}
        background={backdropColor}
        transition={transition}
        onClick={closeHandler}
        isActive={isActive}
        opacity={backdropOpacity}
      />
      <StyledDrawer
        type={type}
        width={width}
        transition={transition}
        isActive={isActive}
        left={position === SideDrawerPosition.left ? drawerCSSLeft : undefined}
        right={position === SideDrawerPosition.right ? drawerCSSRight : undefined}
      >
        <Render {...props} {...renderComponentProps} />
      </StyledDrawer>
      <div>{children}</div>
    </StyledDrawerFrame>
  );

  //#Root is not found in storybook runtime so we need to check if it exists
  return type !== SideDrawerType.wrapped && document.querySelector("#root")
    ? createPortal(renderedJsx, document.querySelector("#root"))
    : renderedJsx;
};

type StyledDrawerFrameProps = {
  type: SideDrawerType;
  isActive: boolean;
};

const StyledDrawerFrame = styled.div<StyledDrawerFrameProps>`
  position: relative;
  overflow: hidden;
  z-index: ${ZIndexStackingContext.high + 1};

  ${({ type }) =>
    type === SideDrawerType.wrapped &&
    css`
      width: 100%;
    `}

  ${({ type }) =>
    (type === SideDrawerType.fixed || type === SideDrawerType.card) &&
    css`
      &,
      &.main-content.override {
        position: fixed;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    `}

  ${({ type, isActive }) =>
    (type === SideDrawerType.fixed || type === SideDrawerType.card) &&
    !isActive &&
    css`
      display: none;
    `}
`;

type StyledDrawerProps = {
  width: string;
  transition: string;
  isActive: boolean;
  type: SideDrawerType;
  left?: string;
  right?: string;
};

const StyledDrawer = styled.aside<StyledDrawerProps>`
  position: absolute;
  transition: ${({ transition }) => transition};
  width: ${({ width }) => width};
  background-color: ${Color.white};
  height: ${({ type }) =>
    type === SideDrawerType.card ? `calc(100% - ${2 * cardTypeDrawerOffsetLength}px)` : "100%"};
  top: ${({ type }) => (type === SideDrawerType.card ? cardTypeDrawerOffsetLength + "px" : "0")};
  ${({ left, right }) =>
    right !== undefined
      ? css`
          right: ${right};
        `
      : css`
          left: ${left};
        `}
  ${({ isActive }) => !isActive && "display: none"}
  ${({ type }) =>
    type === SideDrawerType.card &&
    css`
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 0px 23px 3px ${Color.sky20};
    `}
`;

type StyledDrawerScrimProps = {
  type: SideDrawerType;
  background: string;
  transition: string;
  isActive: boolean;
  opacity: number;
};

const StyledDrawerScrim = styled.div<StyledDrawerScrimProps>`
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: ${({ opacity }) => opacity};
  background: ${({ background, type }) =>
    type === SideDrawerType.card ? "transparent" : background};
  transition: ${({ transition }) => transition};
  ${({ isActive }) => !isActive && "display: none"}
`;
