import React from "react";
import styled from "styled-components";

import { useAppSelector } from "store";
import { routes } from "routes";

import { RecentUpdateRow } from "./RecentUpdateRow";
import { RecentActivitiesPlaceholder } from "./RecentActivitiesPlaceholder";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { EmptyState } from "components/EmptyState";
import { Text } from "components/Text";

import emptyStateImage from "assets/images/no-recent-activity.svg";
import { RecentAnalysis } from "ts/analysis";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { EmptyStateType } from "ts/enums/emptyStateType";

type Props = {
  analysisList: RecentAnalysis[];
  refetchTableData: (id: number) => void;
};

export const RecentUpdatesTable = ({ analysisList, refetchTableData }: Props) => {
  const state = useAppSelector((state) => state.home);

  const getAnalysisList = () => {
    if (!analysisList) return <></>;
    if (analysisList.length === 0)
      return (
        <StyledEmptyStateContainer className="fade-in">
          <EmptyState
            type={EmptyStateType.noListResults}
            image={emptyStateImage}
            titleKey={"recentUpdatesTable.emptyState"}
            captionKey={"recentUpdatesTable.emptyState.description"}
            customStyles={{ marginTop: "0px" }}
          />
        </StyledEmptyStateContainer>
      );
    return analysisList.map((analysis: RecentAnalysis) => (
      <RecentUpdateRow
        analysis={analysis}
        key={analysis.analysisId}
        refetchTableData={refetchTableData}
      />
    ));
  };

  return (
    <StyledRecentUpdates className="fade-in-left">
      <StyledRecentUpdatesHeader>
        <h2>
          <Text resource="recentUpdatesTable.title" />
        </h2>
        <Button
          href={routes.analysisListPage}
          variant={ButtonVariant.secondary}
          size={ButtonSize.sm}
        >
          <Text resource="button.goToAnalysisList" />
          <Icon
            type={IconType.chevronRight}
            color={Color.blue50}
            size={16}
            style={{ marginTop: 2, marginLeft: 2 }}
          />
        </Button>
      </StyledRecentUpdatesHeader>
      <StyledRecentUpdateRowContainter>
        {state.isLoading ? <RecentActivitiesPlaceholder /> : getAnalysisList()}
      </StyledRecentUpdateRowContainter>
    </StyledRecentUpdates>
  );
};

const StyledRecentUpdatesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 6px 0;
  h2 {
    color: ${Color.gray50} !important;
    font-size: 16px;
    margin-left: 10px;
  }
`;

const StyledRecentUpdateRowContainter = styled.div`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  margin-bottom: 15px;
`;

const StyledRecentUpdates = styled.div`
  border-radius: 5px;
  overflow: hidden;
  height: 836px;
  background: ${Color.white};
  display: flex;
  flex-direction: column;

  > * {
    padding: 0 20px;
  }

  width: 387px;
  @media (min-width: 960px) and (max-width: 1366px) {
    min-width: 387px;
  }
`;

const StyledEmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40%;
  padding: 20px;
  text-align: center;
`;
