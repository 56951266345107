import React, { useState } from "react";

import { useResource } from "hooks/useResource";
import useClickOutside from "hooks/useClickOutside";

import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";
import { Tooltip } from "components/Tooltip";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";

export const PinActionButton = () => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [, setIsOpen] = useState<boolean>(false);
  const [, setAnchorEl] = useState<null | HTMLElement>(null);

  const { getResource } = useResource();

  const pinActionIconRef = useClickOutside(() => setIsOpen(false));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
    if (showTooltip) setShowTooltip(false);
  };

  return (
    <div
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      data-tooltip-id={"pin-icon"}
      ref={pinActionIconRef}
    >
      <Button
        variant={ButtonVariant.light}
        square
        size={ButtonSize.sm}
        onClick={handleClick}
        style={{ color: Color.gray20 }}
      >
        <Icon type={IconType.pin} color={Color.gray20} size={12} />
      </Button>
      {showTooltip && (
        <Tooltip
          tooltipId={"pin-icon"}
          content={getResource("comments.pinComment.tooltip")}
          isOpen
        />
      )}
    </div>
  );
};
