import React, { useState } from "react";

import { useResource } from "hooks/useResource";
import useClickOutside from "hooks/useClickOutside";

import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";
import { Tooltip } from "components/Tooltip";
import { CommentDetailsPopup } from "./CommentDetailsPopup";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { Comment } from "ts/comments";

type Props = {
  comment: Comment;
};

export const DetailsActionButton = ({ comment }: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { getResource } = useResource();

  const detailsActionIconRef = useClickOutside(() => setIsOpen(false));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
    if (showTooltip) setShowTooltip(false);
  };

  return (
    <div
      onMouseEnter={() => !isOpen && setShowTooltip(true)}
      onMouseLeave={() => !isOpen && setShowTooltip(false)}
      data-tooltip-id={"details-icon"}
      ref={detailsActionIconRef}
    >
      <Button
        variant={ButtonVariant.light}
        square
        size={ButtonSize.sm}
        onClick={handleClick}
        style={{ color: Color.gray20 }}
      >
        <Icon type={IconType.searchFile} color={Color.gray20} size={12} />
      </Button>
      {showTooltip && (
        <Tooltip
          tooltipId={"details-icon"}
          content={getResource("comments.detailsPopup.tooltip")}
          isOpen
        />
      )}
      <CommentDetailsPopup comment={comment} isOpen={isOpen} anchorEl={anchorEl} />
    </div>
  );
};
