import { TextType } from "./TextType";

export type DropdownMenuItem = {
  label?: TextType;
  href?: string;
  external?: boolean; // for links to an external website
  onClick?: () => void;
  isActive?: boolean;
  isDisabled?: boolean;
  isSubMenu?: boolean;
  value?: string | number;
  show?: boolean;
};

export enum DropdownSize {
  sm = "sm",
  lg = "lg",
}

export type DropdownPosition = {
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  zIndex?: number;
};

export enum DropdownVariant {
  light = "light",
  white = "white",
}
