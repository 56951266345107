import React, { createContext, useCallback, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios, { AxiosError } from "axios";

import { AnalysisContextState, getInitialState } from "./state";
import { resultsReducer } from "./reducer";
import {
  setAnalysisDetails,
  setAnalysisModels,
  setSelectedModelId,
  setAvailableDemographicFilters,
  handleAxiosError,
  setLoadingAnalysisDetails,
  setAnalysisError,
  setAlertThreshold,
  setCommenterDetails,
  setLoadingAnalysisModels,
  setLoadingAvailableDemographicFilters,
  setWidgetsApiData,
  setExcludedDataDetails,
} from "./actions";
import { useCustomReducer } from "hooks/useCustomReducer";
import { useGetModelName } from "hooks/useGetModelName";
import { useQueryParams } from "hooks/useQueryParams";
import { useResource } from "hooks/useResource";
import {
  getTopicsByAnalysisId,
  getDemographicsByAnalysisId,
  getWidgetsByAnalysisId,
  getAnalysisDetails,
  getCommentersCount,
  getEmptyCellCount,
} from "services/analysis";
import { getModels } from "services/models";
import { mapInitialDemographicFiltersForFrontend } from "utils/filters";
import { isAnalyzed } from "utils/isAnalyzed";
import { parseIntIfNumber } from "utils/parseIntIfNumber";
import { formatAnalysisDetails, formatAnalysisModels } from "./helpers";

import { AnalysisStatus, SocketEvent } from "@explorance/mly-types";
import { PageErrorType } from "ts/enums/pageErrorType";
import { AnalysisPageType } from "ts/enums/analysisPageType";
import { useAppSelector } from "store";

type AnalysisContextHandlers = {
  updateLastWidgetsPushedDate: () => void;
};

export const AnalysisContext =
  createContext<[AnalysisContextState, React.Dispatch<any>, AnalysisContextHandlers]>(null);

export const AnalysisContextProvider = ({ children }) => {
  const { getResource } = useResource();
  const getModelName = useGetModelName(getResource);
  const cancelTokenSourceRef = useRef(null);
  const socket = useAppSelector((state) => state.wsStream.socket);
  const { sharingId, sharingPreview } = useQueryParams();
  const { pathname } = useLocation();

  const analysisId = parseIntIfNumber(useParams<{ analysisId: string }>().analysisId) as number;
  const [state, dispatch] = useCustomReducer(resultsReducer, getInitialState(analysisId));

  const isAnalysisPage = Object.keys(AnalysisPageType)
    .map((apt) => apt.toLowerCase())
    .some((p) => pathname.includes(p));

  // Initialize the cancel token when the component mounts and cancel requests when it unmounts
  useEffect(() => {
    cancelTokenSourceRef.current = axios.CancelToken.source();
    return () => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel("AnalysisContext unmounted");
      }
    };
  }, []);

  useEffect(() => {
    if (
      !state.loadingAnalysisDetails &&
      state.analysisDetails.selectedModel?.customModelId &&
      state.selectedModelId === null
    ) {
      dispatch(setSelectedModelId(state.analysisDetails.selectedModel.customModelId));
    }
  }, [state, dispatch]);

  // Set analysis models and associated topic filters
  useEffect(() => {
    const fn = async () => {
      if (isNaN(analysisId)) return dispatch(setAnalysisError(PageErrorType.AnalysisNotFound));
      try {
        const { data: topicsApiData } = await getTopicsByAnalysisId(
          analysisId,
          cancelTokenSourceRef.current.token
        );
        const { data: modelsApiData } = await getModels();

        // Use SelectedModel info if the model used for the analysis is not available or is outdated
        const isCurrentModelUsable =
          state.analysisDetails.selectedModel?.isAvailable &&
          !state.analysisDetails.selectedModel?.isOutdated;
        const selectedModelData = {
          models: {
            [state.analysisDetails.selectedModel?.family]: [
              {
                graphId: state.analysisDetails.selectedModel?.graphId,
                languages: state.analysisDetails.selectedModel?.languages,
                isPowerset: state.analysisDetails.selectedModel?.isPowerset,
                env: state.analysisDetails.selectedModel?.env,
              },
            ],
          },
        };
        const modelsData = !isCurrentModelUsable ? selectedModelData : modelsApiData;

        // Get available model filters based on selected model info
        const availableModels = topicsApiData.categorizedTopics.filter(
          (d) => !d.isVirtual || d.modelId === state.analysisDetails.selectedModel?.customModelId
        );
        const analysisModelsResult = await formatAnalysisModels(
          modelsData,
          availableModels,
          getModelName
        );
        dispatch(setAnalysisModels(analysisModelsResult));
        dispatch(setLoadingAnalysisModels(false));
      } catch (err) {
        dispatch(handleAxiosError(err as AxiosError<any>));
      }
    };

    if (!state.loadingAnalysisDetails) fn();
  }, [analysisId, dispatch, state.analysisDetails.status, state.loadingAnalysisDetails]); // eslint-disable-line

  // Fetch "analysisDetails" and set related states
  const fetchAndSetAnalysisDetails = useCallback(async () => {
    if (isNaN(analysisId)) return dispatch(setAnalysisError(PageErrorType.AnalysisNotFound));
    try {
      const { data: analysisDetailsData } = await getAnalysisDetails(
        analysisId,
        state.previewUser.id
      );
      if (isAnalysisPage) {
        if (analysisDetailsData.status === AnalysisStatus.NotAnalyzed) {
          dispatch(setAnalysisError(PageErrorType.AnalysisNotAnalyzed));
        }
        if (analysisDetailsData.status === AnalysisStatus.Failed) {
          dispatch(setAnalysisError(PageErrorType.AnalysisFailed));
        }
      }
      dispatch(setAnalysisDetails(formatAnalysisDetails(analysisId, analysisDetailsData)));
      dispatch(setAlertThreshold(analysisDetailsData.settings?.alert?.threshold));
    } catch (err) {
      dispatch(handleAxiosError(err as AxiosError<any>));
    } finally {
      dispatch(setLoadingAnalysisDetails(false));
    }
  }, [analysisId, dispatch, state.previewUser.id, isAnalysisPage]);

  useEffect(() => {
    fetchAndSetAnalysisDetails();
  }, [fetchAndSetAnalysisDetails]);

  // Fetch available demographic filters and watch for changes in the preview user
  useEffect(() => {
    getDemographicsByAnalysisId({
      analysisId,
      cancelToken: cancelTokenSourceRef.current.token,
      ...(sharingPreview && { sharing_id: sharingId, user_id: state.previewUser.id }),
    })
      .then(({ data: demographicsData }) => {
        dispatch(
          setAvailableDemographicFilters(
            mapInitialDemographicFiltersForFrontend(demographicsData.analysis.demographics)
          )
        );
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          dispatch(handleAxiosError(err as AxiosError<any>));
        }
      })
      .finally(() => dispatch(setLoadingAvailableDemographicFilters(false)));
  }, [state.previewUser, analysisId, dispatch, sharingId]); // eslint-disable-line

  // Fetch unique commenters, empty comments, and set related states
  useEffect(() => {
    const fn = async () => {
      try {
        // Unique Commenters
        if (isAnalyzed(state.analysisDetails.status)) {
          const { data: commenterData } = await getCommentersCount(analysisId);
          dispatch(
            setCommenterDetails({
              commentersColumn: commenterData.commenterColumn,
              commentersCount: commenterData.uniqueCommenters,
            })
          );
        }

        // Excluded Comments/Empty Cells
        if (isAnalyzed(state.analysisDetails.status)) {
          const { data: excludedData } = await getEmptyCellCount(analysisId);
          const totalCount = [
            ...excludedData.emptyComments,
            ...excludedData.excludedComments,
          ].reduce((acc, comment) => (acc += comment.count), 0);

          dispatch(
            setExcludedDataDetails({
              totalColumns: excludedData.selectedColumns,
              totalEmptyCells: totalCount,
              emptyCommentCells: excludedData.emptyComments,
              excludedComments: excludedData.excludedComments,
            })
          );
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          dispatch(handleAxiosError(err as AxiosError<any>));
        }
      }
    };

    fn();
  }, [analysisId, dispatch, state.analysisDetails.status]);

  const updateLastWidgetsPushedDate = async () => {
    await getWidgetsByAnalysisId({ analysisId, sharingId, user_id: state.previewUser.id })
      .then(({ data }) => {
        dispatch(setWidgetsApiData(data));
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (!socket) return;

    socket.on(SocketEvent.ChangedJobStatus, fetchAndSetAnalysisDetails);

    return () => {
      socket.off(SocketEvent.ChangedJobStatus, fetchAndSetAnalysisDetails);
    };
  }, [socket, fetchAndSetAnalysisDetails]);

  const handlers = {
    updateLastWidgetsPushedDate,
  };

  return (
    <AnalysisContext.Provider value={[state, dispatch, handlers]}>
      {children}
    </AnalysisContext.Provider>
  );
};
