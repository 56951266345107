import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";
import { AnalysisContext } from "context/AnalysisContext";

import { routes } from "routes";
import { runAnalysis } from "services/analysis";
import { findPrecisionLevel } from "utils/precision";
import { isAnyAdmin } from "utils/isAnyAdmin";
import { isDegraded } from "utils/isDegraded";
import {
  clearState,
  setShowOverwriteModal,
  setAnalysisDetails,
  initializeSettings,
} from "store/analysisSettings/settingsSlice";
import { fetchUsedModels } from "store/analysisSettings/thunks";
import { showToastError } from "store/toast/toastSlice";

import { SelectionBody } from "./_layouts/SelectionBody";
import { AnalysisJobProcessingModal } from "components/_modals/JobProcessingModal";
import { JobFailedModal } from "../data-source/_layouts/JobFailedModal";
import { Header } from "pages/analysis/[id]/data-source/_layouts/Header";
import { formatCommentListForBackEnd } from "./_layouts/PreFiltering/helpers";
import { Button } from "components/_buttons/Button";
import { Notch } from "common-layouts/AnalysisLayout/Notch";
import { StyledButtonContainer, StyledNotchContainer } from "../data-source";
import { OverwriteAnalysisConfirmationModal } from "components/_modals/OverwriteAnalysisConfirmationModal";
import { ErrorScreen } from "components/ErrorScreen";
import { Text } from "components/Text";
import { LoadingDots } from "components/LoadingDots";

import { Color } from "ts/enums/color";
import {
  AnalysisStatus,
  // LanguageCode,
  ErrorType,
  FileUploadStatus,
  PermissionLevel,
  RoleType,
} from "@explorance/mly-types";
import { AnalysisJobInput } from "ts/analysis";
import { ButtonVariant, ButtonSize } from "ts/enums/button";
import { PageErrorType } from "ts/enums/pageErrorType";
import {
  setImportModalAnalysisId,
  setImportModalFileId,
  setImportModalOpen,
} from "store/importProcess/importModalSlice";
import { DataSourceModal } from "common-layouts/DataSourceModal";
import { FeatureFlag } from "components/FeatureFlag";
import { Feature } from "ts/enums/feature";
import { DataSourceModalType } from "ts/enums/dataSourceModalType";

export const SettingsPage = () => {
  // redux
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);
  const state = useAppSelector((state) => state.settings);

  // local state
  const [analysisState] = useContext(AnalysisContext);
  const { analysisId } = useParams<{ analysisId: string }>();

  const history = useHistory();

  const isModelOutdated =
    (!analysisState.analysisDetails.selectedModel &&
      !!analysisState.analysisDetails.suggestedModel) ||
    analysisState.analysisDetails.selectedModel?.isOutdated;

  const isAdmin = isAnyAdmin(currentUser.roleType);
  const isSharingViewer =
    analysisState.analysisDetails?.sharing?.permissionLevel === PermissionLevel.View;
  const isRoleViewer = currentUser.roleType === RoleType.Viewer;

  const precisionLevel = findPrecisionLevel(state.selectedPrecision);

  const startJob = async () => {
    try {
      const jobInput: AnalysisJobInput = {
        selectedColumns: state.selectedColumns,
        graphId: state.selectedModel?.graphId || null,
        customModelId: state.selectedCustomModel?.customModelId || null,
        env: state.selectedEnv,
        ...(precisionLevel && { precision: precisionLevel }),
        commenterColumn: state.selectedCommenterColumn,
        variableMappingId: state.selectedVariableMapping,
        excludedValues: formatCommentListForBackEnd(state.excludedCommentList),
        // ...{ translationLanguages: [LanguageCode.Eng] },
        //...{ translationLanguages: [] }, @Todo: revert this when translation API quota situation is resolved
      };
      await runAnalysis(parseInt(analysisId), jobInput);
      history.push(routes.homePage);
    } catch (err: any) {
      console.error(err);
      if (err.response?.status === 423) {
        dispatch(
          showToastError({
            message: {
              titleKey: "toast.analyzedCommentsQuota1",
              captionKey: "toast.analyzedCommentsQuota2",
            },
          })
        );
      } else if (err.response?.data.details === ErrorType.ModelOutdated) {
        dispatch(showToastError("toast.modelOutdated"));
      } else {
        dispatch(showToastError("toast.defaultError"));
      }
    }
  };

  const handleClickAnalyze = () => {
    if (analysisState.analysisDetails.status === AnalysisStatus.Completed) {
      return dispatch(setShowOverwriteModal(true));
    }
    startJob();
  };

  const handleOverwriteAnalysis = () => {
    dispatch(setShowOverwriteModal(false));
    startJob();
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      state.selectedModel ||
      analysisState.loadingAnalysisModels ||
      analysisState.loadingAnalysisDetails ||
      !analysisState.analysisDetails
    ) {
      return;
    }

    const details = analysisState.analysisDetails;
    dispatch(setAnalysisDetails(details));

    dispatch(initializeSettings({ isModelOutdated, details }));
    dispatch(fetchUsedModels(analysisId));

    return () => {
      dispatch(clearState());
    };
  }, [
    analysisState.loadingAnalysisDetails,
    analysisState.loadingAnalysisModels,
    analysisId,
    dispatch,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  if ((isRoleViewer && analysisState.analysisDetails.sharing) || (!isAdmin && isSharingViewer)) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <>
      {analysisState.analysisError &&
      analysisState.analysisError !== PageErrorType.AnalysisNotAnalyzed ? (
        <ErrorScreen errorType={analysisState.analysisError} />
      ) : analysisState.loadingAnalysisDetails ? (
        <StyledLoadingDotsContainer>
          <LoadingDots />
        </StyledLoadingDotsContainer>
      ) : (
        <StyledSettingsPage className="fade-in">
          <StyledNotchContainer>
            <Notch />
          </StyledNotchContainer>
          <AnalysisJobProcessingModal />
          <JobFailedModal />
          <OverwriteAnalysisConfirmationModal
            isOpen={state.showOverwriteModal}
            onCancel={() => dispatch(setShowOverwriteModal(false))}
            onConfirm={handleOverwriteAnalysis}
          />
          <FeatureFlag feature={Feature.importProgressModal}>
            <DataSourceModal
              fileUploadStatus={analysisState.analysisDetails.uploadStatus}
              type={
                analysisState.analysisDetails.uploadStatus === FileUploadStatus.Completed
                  ? DataSourceModalType.ViewData
                  : DataSourceModalType.ImportFile
              }
            />
          </FeatureFlag>
          <StyledSettingsContainer>
            <Header
              titleKey={"analysisSettings.title"}
              descriptionKey={"analysisSettings.description"}
              handleClickBackToPrevious={() =>
                state.analysisDetails.status === AnalysisStatus.NotAnalyzed ||
                state.analysisDetails.status === AnalysisStatus.Failed
                  ? history.push(routes.homePage)
                  : history.push(routes.overviewPage(parseInt(analysisId)))
              }
            />
            <StyledSettingsBodyContainer>
              <SelectionBody isModelOutdated={isModelOutdated} />
            </StyledSettingsBodyContainer>
          </StyledSettingsContainer>
          <StyledButtonContainer>
            <Button
              variant={ButtonVariant.outline}
              size={ButtonSize.ml}
              onClick={() => history.push(routes.overviewPage(parseInt(analysisId)))}
            >
              <Text resource="button.cancel" />
            </Button>
            <Button
              variant={ButtonVariant.outlineBlue}
              size={ButtonSize.ml}
              onClick={() => {
                analysisState.analysisDetails.uploadStatus === FileUploadStatus.Completed
                  ? dispatch(setImportModalAnalysisId(analysisState.analysisDetails.id))
                  : dispatch(setImportModalFileId((analysisState.analysisDetails as any).fileId)); //@Todo: We need the fileId from the BE in GET analysis/:id
                dispatch(setImportModalOpen(true));
              }}
            >
              <Text resource="button.editDataTypes" />
            </Button>
            <Button
              variant={ButtonVariant.primary}
              size={ButtonSize.ml}
              onClick={handleClickAnalyze}
              disabled={
                state.selectedColumns.length === 0 ||
                isDegraded(currentUser, analysisState) ||
                isRoleViewer
              }
            >
              <Text resource="button.analyze" />
            </Button>
          </StyledButtonContainer>
        </StyledSettingsPage>
      )}
    </>
  );
};

const StyledSettingsPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledSettingsBodyContainer = styled.div`
  border: 1px solid ${Color.sky50};
  width: 100%;
  padding: 16px;
  height: fit-content;
  border-radius: 2px;
`;

const StyledSettingsContainer = styled.div`
  padding-bottom: 16px;
`;

const StyledLoadingDotsContainer = styled.div`
  margin-top: 25%;
  margin-left: 50%;
`;
