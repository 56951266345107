import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { isEqual } from "lodash";

import { Text } from "components/Text";
import { DropdownMenu } from "components/DropdownMenu";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { Tooltip } from "components/Tooltip";

import { useResource } from "hooks/useResource";
import useClickOutside from "hooks/useClickOutside";
import { getRandomNumber } from "utils/getRandomNumber";

import { DropdownMenuItem, DropdownPosition } from "ts/dropdown";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { SelectOption } from "ts/select";
import { Color } from "ts/enums/color";

type LabelStyle = {
  fontWeight: string;
  color: Color;
};

type SelectStyle = {
  margin?: string;
  padding?: string;
  width?: string;
};

type Props = {
  selectedOption: SelectOption;
  options: SelectOption[];
  handleChange: (arg) => void;
  selectTitleKey?: string;
  selectLabelKey?: string;
  dropdownWidth?: string;
  buttonVariant?: ButtonVariant;
  buttonSize?: ButtonSize;
  buttonStyle?: Record<string, string | number>;
  labelStyle?: LabelStyle;
  dropdownPosition?: DropdownPosition;
  flex?: string;
  dropdownMaxHeight?: string;
  hideDropdown?: boolean;
  iconColor?: Color;
  addNoneOption?: boolean;
  selectStyle?: SelectStyle;
  disabled?: boolean;
};

const BUTTON_HEIGHT = 35;

export const Select = ({
  selectedOption,
  options,
  handleChange,
  selectTitleKey,
  selectLabelKey,
  dropdownWidth,
  buttonVariant = ButtonVariant.outlineGray,
  buttonSize = ButtonSize.md,
  buttonStyle: buttonStyleProp,
  iconColor,
  labelStyle,
  dropdownPosition = {},
  flex = "none",
  dropdownMaxHeight = null,
  addNoneOption,
  selectStyle,
  disabled,
}: Props) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [localSelectOptions, setLocalSelectOptions] = useState<DropdownMenuItem[]>(options);
  const [selectContainerHeight, setSelectContainerHeight] = useState<number>(0);

  const [subMenuOptions, setSubMenuOptions] = useState<SelectOption[]>([]);
  const [subMenuOptionIndex, setSubMenuOptionIndex] = useState<number>(null);
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);

  const { getResource } = useResource();
  const selectContainerRef = useClickOutside(() => {
    setMenuOpen(false);
    setSubMenuOpen(false);
    setSubMenuOptionIndex(null);
  });

  const buttonLabelRef = useRef<HTMLDivElement>();
  const randomId = getRandomNumber();

  const buttonStyle = {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "normal",
    height: BUTTON_HEIGHT,
    ...buttonStyleProp,
  };

  if (iconColor) {
    buttonStyle["color"] = iconColor;
  }

  const toggleSelectMenu = () => {
    if (disabled) return;
    setMenuOpen(!menuOpen);
    setSubMenuOpen(false);
  };

  const handleSelectMenuOption = (value: string | number) => {
    const optionIndex = options.findIndex((o) => o.value === value);
    const option = options[optionIndex];
    if (option?.childOptions) {
      const sameSubMenu = subMenuOptionIndex === optionIndex;
      const closingMenu = sameSubMenu && subMenuOpen;
      setSubMenuOptions(() => {
        return option.childOptions.map((o) => ({
          ...o,
          isActive: isEqual(selectedOption?.value, o.value),
        }));
      });
      setSubMenuOptionIndex(optionIndex);
      setSubMenuOpen(!closingMenu || !subMenuOpen);
      return;
    }
    setMenuOpen(false);
    setSubMenuOpen(false);
    handleChange && handleChange(value);
  };

  useEffect(() => {
    if (selectContainerRef.current) {
      setSelectContainerHeight(selectContainerRef.current.getBoundingClientRect().height);
    }
  }, [selectContainerRef]);

  useEffect(() => {
    const noneOption = { label: getResource("dropdown.none"), value: null };
    const optionsWithSubMenu = options.map((o) => ({
      ...o,
      isSubMenu: !!o?.childOptions,
    })) as DropdownMenuItem[];
    const dropdownItems = addNoneOption ? [noneOption, ...optionsWithSubMenu] : optionsWithSubMenu;
    setLocalSelectOptions(dropdownItems);
  }, [addNoneOption, options]); // eslint-disable-line

  useEffect(() => {
    if (buttonLabelRef.current) {
      setShowTooltip(
        buttonLabelRef.current.offsetWidth < buttonLabelRef.current.scrollWidth ||
          buttonLabelRef.current.offsetHeight < buttonLabelRef.current.scrollHeight
      );
    }

    const activeOption = options.find((o) => {
      if (o.childOptions) {
        return o.childOptions.find((co) => isEqual(selectedOption?.value, co.value));
      }
      return isEqual(selectedOption?.value, o.value);
    });

    const activeOptionParent = options.find((o) =>
      o.childOptions?.find((co) => isEqual(selectedOption?.value, co.value))
    );

    setLocalSelectOptions((prev) =>
      prev.map((o) => ({
        ...(o.label === null ? { label: getResource("dropdown.none"), value: null } : o),
        isActive:
          isEqual(activeOption?.value, o?.value) || isEqual(activeOptionParent?.value, o?.value),
      }))
    );
  }, [selectedOption]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledSelectContainer
      ref={selectContainerRef}
      flex={flex}
      selectStyle={selectStyle}
      hasLabelKey={!!selectLabelKey}
    >
      {selectTitleKey && (
        <StyledSelectionTitle>
          <Text resource={selectTitleKey} />
        </StyledSelectionTitle>
      )}
      {selectLabelKey && (
        <StyledSelectionLabel>
          <Text resource={selectLabelKey} />
        </StyledSelectionLabel>
      )}
      <Button
        style={buttonStyle}
        variant={buttonVariant}
        size={buttonSize}
        onClick={toggleSelectMenu}
        disabled={disabled}
      >
        <StyledButtonLabel
          ref={buttonLabelRef}
          data-tooltip-id={String(randomId)}
          labelStyle={labelStyle}
        >
          {selectedOption.label}
          {showTooltip && (
            <Tooltip tooltipId={randomId.toString()} content={selectedOption.label} />
          )}
        </StyledButtonLabel>
        <Icon type={IconType.triangleFilledDown} size={8} style={{ marginLeft: 8 }} />
      </Button>
      {menuOpen && (
        <DropdownMenu
          position={{ zIndex: ZIndexStackingContext.high, ...dropdownPosition }}
          items={localSelectOptions}
          show={menuOpen}
          onChange={(value) => handleSelectMenuOption(value)}
          width={dropdownWidth}
          maxHeight={dropdownMaxHeight}
        />
      )}
      {subMenuOpen && (
        <DropdownMenu
          position={{
            zIndex: ZIndexStackingContext.high,
            left: selectContainerRef.current.offsetWidth,
            top: 8 + selectContainerHeight + BUTTON_HEIGHT * subMenuOptionIndex,
          }}
          items={subMenuOptions}
          show={subMenuOpen}
          onChange={(value) => {
            setSubMenuOpen(false);
            setMenuOpen(false);
            handleChange && handleChange(value);
          }}
          width={dropdownWidth}
          maxHeight={dropdownMaxHeight}
        />
      )}
    </StyledSelectContainer>
  );
};

const StyledSelectContainer = styled.div<{
  flex: string;
  selectStyle: SelectStyle;
  hasLabelKey: boolean;
}>`
  position: relative;
  flex: ${({ flex }) => flex};
  margin: ${({ selectStyle }) => selectStyle?.margin};
  padding: ${({ selectStyle }) => selectStyle?.padding};
  width: ${({ selectStyle }) => selectStyle?.width};
  flex-direction: ${({ hasLabelKey }) => hasLabelKey && "row"};
  align-items: center;
  display: ${({ hasLabelKey }) => hasLabelKey && "flex"};
`;

const StyledButtonLabel = styled.div<{ labelStyle?: LabelStyle }>`
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
  font-weight: ${({ labelStyle }) => labelStyle?.fontWeight};
  color: ${({ labelStyle }) => labelStyle?.color};
`;

const StyledSelectionTitle = styled.div`
  font-size: 12px;
  color: ${Color.gray20};
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledSelectionLabel = styled.div`
  font-size: 15px;
  margin-right: 6px;
  color: ${Color.gray50};
`;
