import React, { ReactNode, useEffect, useState } from "react";
import { Fade, Popper } from "@mui/material";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { PopupPosition } from "ts/enums/popupPosition";

type PopupStyles = {
  padding?: number;
  maxHeight?: number;
  maxWidth?: number;
  width?: number;
};

type Props = {
  children: ReactNode;
  popupStyles: PopupStyles;
  placement: PopupPosition;
  anchorEl: HTMLElement;
  isOpen: boolean;
  transitionDuration?: number;
  fixedPopup?: boolean;
};

const DEFAULT_TRANSITION_DURATION = 350;

export const Popup = ({
  children,
  isOpen,
  popupStyles,
  placement,
  transitionDuration,
  anchorEl,
  fixedPopup,
}: Props) => {
  const [fixedAnchorPosition, setFixedAnchorPosition] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (anchorEl && fixedPopup) {
      const virtualEl = document.createElement("div");
      virtualEl.style.position = "absolute";
      virtualEl.style.top = `${anchorEl.getBoundingClientRect().bottom}px`;
      virtualEl.style.left = `${anchorEl.getBoundingClientRect().left}px`;
      document.body.appendChild(virtualEl);
      setFixedAnchorPosition(virtualEl);

      return () => {
        document.body.removeChild(virtualEl);
      };
    }
  }, [anchorEl, fixedPopup]);

  return (
    <div>
      {isOpen && (
        <Popper
          open={isOpen}
          placement={placement}
          anchorEl={fixedPopup ? fixedAnchorPosition : anchorEl}
          modifiers={[
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                rootBoundary: "viewport",
              },
            },
          ]}
          style={{
            zIndex: 100,
          }}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={transitionDuration ?? DEFAULT_TRANSITION_DURATION}>
              <StyledPopperContainer popupStyles={popupStyles}>{children}</StyledPopperContainer>
            </Fade>
          )}
        </Popper>
      )}
    </div>
  );
};

const StyledPopperContainer = styled.div<{
  popupStyles: PopupStyles;
}>`
  padding: ${({ popupStyles }) => popupStyles.padding ?? 10}px;
  width: ${({ popupStyles }) => (popupStyles.width ? `${popupStyles.width}px` : "fit-content")};
  max-width: ${({ popupStyles }) => popupStyles.maxWidth && popupStyles.maxWidth}px;
  max-height: ${({ popupStyles }) => popupStyles.maxHeight && popupStyles.maxHeight}px;
  background: ${Color.white};
  box-shadow: 0px 3px 6px ${Color.shadow10};
  border: 1px solid ${Color.sky50};
  border-radius: 2px;
  overflow-y: auto;
`;
