import React, { useContext } from "react";
import styled from "styled-components";

import { AnalysisContext } from "context/AnalysisContext";

import { BackButton } from "components/_buttons/BackButton";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";
import { AnalysisStatus } from "@explorance/mly-types";
import { ImportMoreDataButton } from "./ImportMoreDataButton";

type Props = {
  titleKey: string;
  descriptionKey?: string;
  totalCount?: string;
  handleClickBackToPrevious: () => void;
};

export const Header = ({
  titleKey,
  descriptionKey,
  totalCount,
  handleClickBackToPrevious,
}: Props) => {
  const [state] = useContext(AnalysisContext);

  return (
    <StyledHeader>
      <StyledBackButtonContainer>
        <BackButton
          resourceKey={
            state.analysisDetails.status === AnalysisStatus.NotAnalyzed ||
            state.analysisDetails.status === AnalysisStatus.Failed
              ? "button.backToHome"
              : "button.backToOverviewPage"
          }
          onClick={handleClickBackToPrevious}
        />
      </StyledBackButtonContainer>
      <StyledTopContainer>
        <div>
          <StyledTitle>
            <Text resource={titleKey} />
          </StyledTitle>
          <StyledDescription>
            <Text resource={descriptionKey} />
            {totalCount && <StyledTotalCount>{totalCount}</StyledTotalCount>}
          </StyledDescription>
        </div>
        <StyledImportContainer>
          <ImportMoreDataButton />
        </StyledImportContainer>
      </StyledTopContainer>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  cursor: default;
  width: 100%;
`;

const StyledBackButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const StyledTopContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
`;

const StyledTitle = styled.div`
  font-size: 1em;
  display: block;
  color: ${Color.gray50};
  font-weight: bold;
`;

const StyledDescription = styled.div`
  display: flex;
  margin-top: 5px;
  font-size: 0.875em;
`;

const StyledTotalCount = styled.span`
  font-weight: bold;
`;

const StyledImportContainer = styled.div`
  margin-left: auto;
`;
