import React from "react";
import styled from "styled-components";

import { Skeleton } from "components/Skeleton";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";

export const UploadFileStep = () => (
  <StyledUploadStepContainer>
    <StyledHeaderContainer>
      <StyledTitleContainer>
        <b>
          <Text resource="modal.import.stepOne.title" />
        </b>
        <Skeleton width={450} height={18} borderRadius="5px" />
      </StyledTitleContainer>
      <Skeleton width={267} height={34} borderRadius="5px" />
    </StyledHeaderContainer>
    <StyledProgressBar>
      <StyledCurrentProgress />
    </StyledProgressBar>
    <StyledBodySection></StyledBodySection>
  </StyledUploadStepContainer>
);

const StyledUploadStepContainer = styled.div`
  width: 1170px;
  @media (max-width: 1366px) {
    width: 950px;
  }
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const StyledProgressBar = styled.div`
  width: calc(100% + 48px);
  height: 5px;
  margin-left: -24px;
  background-color: ${Color.blue20};
  margin-bottom: 12px;
`;
const StyledCurrentProgress = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${Color.green50};
`;

const StyledBodySection = styled.div`
  height: 530px;
`;
